import React from 'react';
import '../../pages/fabrisorb/slider-fabrisorbceiling';
import Ceiling1 from '../../images/newproductslider/polysorbdecocloud1.jpeg';
import Ceiling2 from '../../images/newproductslider/polysorbdecocloud2.jpeg';
function SliderPolysorbDecoCloud() {
	return (
	  
	<>
	<div id="slider-fabrisorbceiling">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Ceiling1} alt="Ceiling1" />
	  				<div className="carousel-caption d-none d-md-block">
						<h2>Ceiling Panels</h2>
    				</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Ceiling2} alt="Ceiling2" />
	  				<div className="carousel-caption d-none d-md-block">
		  				<h2>Ceiling Panels</h2>
    				</div>
				</div>
    		</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default SliderPolysorbDecoCloud;