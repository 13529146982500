import React, { useState, useEffect } from "react";
import './carousel-home.css';
import Slider from "react-slick";
import ProjectASI1 from '../../images/Teleperformance.webp';
import ProjectASI2 from '../../images/projects-slider/bosch.webp';
import ProjectASI3 from '../../images/project4.webp';
import ProjectASI4 from '../../images/project5.webp';
import ProjectASI5 from '../../images/project1.webp';
import ProjectASI6 from '../../images/projects-slider/wipro-bangalore.webp';
import ProjectASI7 from '../../images/project2.webp';
import ProjectASI8 from '../../images/project3.webp';
import ProjectASI9 from '../../images/projects-slider/naval-vizag.webp';
import ProjectASI10 from '../../images/projects-slider/proj-kodai-3.webp';
import ProjectASI11 from '../../images/projects-slider/cmc-image-1.webp';
import ProjectASI12 from '../../images/projects-slider/hospira-chennai.webp';
import ProjectASI13 from '../../images/projects-slider/ht-mangalore-1.webp';
import ProjectASI14 from '../../images/projects-slider/flocert-1.webp';
import ProjectASI15 from '../../images/projects-slider/amaryllis-1.webp';
import ProjectASI16 from '../../images/projects-slider/p1ferrari.webp';
import ProjectASI17 from '../../images/projects-slider/p1museum.webp';
import ProjectASI18 from '../../images/projects-slider/p1palace.webp';
import ProjectASI19 from '../../images/projects-slider/p1louvre.webp';
import ProjectASI20 from '../../images/projects-slider/p1eibfs.webp';
import ProjectASI21 from '../../images/projects-slider/p1kapsarc.webp';
import ProjectASI22 from '../../images/projects-slider/acousticproject-acoustical-surfaces.webp';

import ProjectASI23 from '../../images/bhutanmain.jpeg';
import ProjectASI24 from '../../images/Project_Images/biocon.jpeg';
import ProjectASI25 from '../../images/Epam.jpeg';
import ProjectASI26 from '../../images/dlf.jpeg';
import ProjectASI27 from '../../images/kalaignar.jpeg';
import ProjectASI28 from '../../images/ipopaudio.jpeg';
import ProjectASI29 from '../../images/myntra.jpeg';
import ProjectASI30 from '../../images/hdfc.jpeg';
import ProjectASI31 from '../../images/Project_Images/Sabic_3.jpg';
import ProjectASI32 from '../../images/Project_Images/Bajaj_Plaster_3.jpeg';
import ProjectASI33 from '../../images/Project_Images/Ford_2.jpeg';

function Carousel() {
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    const projects = [
        { src: ProjectASI1, alt: "FabriwallAS1", link: "/project-teleperformance", title: "Teleperformance SE", scope: "Supply & Installation of D'ecopanel" },
        { src: ProjectASI2, alt: "FabriwallAS2", link: "/project-bosch", title: "Robert Bosch Engineering", scope: "Supply & Installation of Fabriwall" },
        { src: ProjectASI3, alt: "FabriwallAS3", link: "/project-twitter", title: "Twitter India", scope: "Acoustical Wall Treatment" },
        { src: ProjectASI4, alt: "FabriwallAS4", link: "/project-zoho", title: "Zoho Corporation", scope: "Acoustic Panel System and Acrylic Sheet" },
        { src: ProjectASI5, alt: "FabriwallAS5", link: "/project-general-motors", title: "General Motors", scope: "Acoustic Anaechoic Chamber" },
        { src: ProjectASI6, alt: "FabriwallAS6", link: "/project-wipro", title: "Wipro Technologies, Bangalore, Karnataka", scope: "Supply & installation of Acoustic Wall Treatment Acoustic" },
        { src: ProjectASI7, alt: "FabriwallAS7", link: "/project-goldman-sachs", title: "Goldman Sachs", scope: "Acoustic Wall Treatment" },
        { src: ProjectASI8, alt: "FabriwallAS8", link: "/project-drc-cinemas", title: "DRC Cinemas", scope: "Acoustic Treatment" },
        { src: ProjectASI9, alt: "FabriwallAS9", link: "/project-navel-base-academy", title: "Navel Base Academy", scope: "Supply & Installation of Acoustic Panel" },
        { src: ProjectASI10, alt: "FabriwallAS10", link: "/project-kodaikanal-international-school", title: "Kodaikanal International School", scope: "Supply & Installation of Acoustic Wall Panels" },
        { src: ProjectASI11, alt: "FabriwallAS11", link: "/project-christian-medical-college", title: "Christian Medical College", scope: "Supply & Installation of Sound Absorption Wall Paneling of NRC 0.9 and Suspended Ceilings" },
        { src: ProjectASI12, alt: "FabriwallAS12", link: "/project-hospira-healthcare", title: "Hospira Healthcare", scope: "Supply & Installation of Acoustic Wall Panels" },
        { src: ProjectASI13, alt: "FabriwallAS13", link: "/home-theater-project", title: "Home Theater, Mangalore", scope: "Supply & Installation of Acoustic Walls and Flooring" },
        { src: ProjectASI14, alt: "FabriwallAS14", link: "/project-flocert", title: "Flocert India, Bangalore", scope: "Supply & Installation of Acoustical Fabric panels with Echo Eliminator" },
        { src: ProjectASI15, alt: "FabriwallAS15", link: "/project-amaryllis-healthcare", title: "Amaryllis Healthcare Pvt Ltd, Bangalore", scope: "Supply & Installation of Soundproof Vinyl Barrier and Stretch Fabric System" },
        { src: ProjectASI16, alt: "FabriwallAS16", link: "/project-ferrari-world", title: "Ferrari World, Abu dhabi", scope: "Installed Fabric Wrapped Acoustic Wall Panel System on the Wall" },
        { src: ProjectASI17, alt: "FabriwallAS17", link: "/project-the-museum-of-future-dubai", title: "The Museum of Future, Dubai", scope: "Acoustical Plaster Ceiling in Auditorium below the Walkway & Hall Area" },
        { src: ProjectASI18, alt: "FabriwallAS18", link: "/project-presidential-palace", title: "Presidential Palace, Abu dhabi", scope: "Supply & Installation of Acoustic Wall Panels" },
        { src: ProjectASI19, alt: "FabriwallAS19", link: "/project-louvre", title: "Louvre, Abu dhabi", scope: "Supply & Installation of Acoustic Wood Wool" },
        { src: ProjectASI20, alt: "FabriwallAS20", link: "/project-EIBFS", title: "EIBFS, Dubai, UAE", scope: "Acoustical Wall Treatment & Ceiling" },
        { src: ProjectASI21, alt: "FabriwallAS21", link: "/project-kapsarc", title: "KAPSARC, Riyadh, Saudi Arabia", scope: "Supply & installation of Acoustic Panels, Echo Panels and Acoustic Treatment" },
        { src: ProjectASI22, alt: "FabriwallAS22", link: "/project-delhi-public-school", title: "Delhi Public School, Bangalore, Karnataka", scope: "Supply & installation of Soundproof Fabrisorb Baffles" },
        { src: ProjectASI23, alt: "FabriwallAS23", link: "/project-bhutan", title: "GCIT Bhutan", scope: "Supply & Installation of POLYSORB WALL PANELS & CLOUDS" },
        { src: ProjectASI24, alt: "FabriwallAS24", link: "/project-biocon", title: "Biocon", scope: "Supply and Installation of FABRIWALL system." },
        { src: ProjectASI25, alt: "FabriwallAS25", link: "/project-epam", title: "Epam", scope: "Supply and Installation of Acoustic Plaster." },
        { src: ProjectASI26, alt: "FabriwallAS26", link: "/project-dlf", title: "DLF The Camellias", scope: "Supply & Installation of Fabriwall Acoustic Plaster in ceilings." },
        { src: ProjectASI27, alt: "FabriwallAS27", link: "/project-kalaignar-library", title: "Kalaignar Memorial Library", scope: "Supply & Installation of FABRIWALL, D’ECOPANEL-Baffles & ENVIROCOUSTIC WOOD WOOL." },
        { src: ProjectASI28, alt: "FabriwallAS28", link: "/project-ipop", title: "Ipop Audio-Experience Centre", scope: "Assessment, Planning, Customisation and Supply of D'ecopanel to achieve a great appeal to acoustics as well as aesthetics." },
        { src: ProjectASI29, alt: "FabriwallAS29", link: "/project-myntra", title: "Myntra", scope: "Supply and installation of Fabrisorb Wall panels and Clouds." },
        { src: ProjectASI30, alt: "FabriwallAS30", link: "/project-hdfc", title: "HDFC Auditorium", scope: "Supply and Installation of FABRIWALL System." },
        { src: ProjectASI31, alt: "FabriwallAS31", link: "/project-sabic-research", title: "Sabic Research & Technology Pvt. Ltd", scope: "Supplied and installedacoustical doors and partitions at Sabic." },
        { src: ProjectASI32, alt: "FabriwallAS32", link: "/project-bajaj-electronics", title: "Bajaj Electronics", scope: "Supplied high-quality acoustic plaster and superior results, enhancing sound absorption." },
        { src: ProjectASI33, alt: "FabriwallAS33", link: "/project-ford", title: "Ford Global Technology and Business Center (GTBC)", scope: "Supply and installation of the Fabriwall system." },
    ];


    return (
        <>

            <div>
                <div className="carousel-container">
                    <Slider {...settings}>
                        {projects.map((project, index) => (
                            <div key={index}>
                                <a href={project.link}>
                                    <div className='image-container'>
                                        <img className="img-fluid" src={project.src} alt={project.title} style={{ width: "300px", height: "300px", objectFit: "cover", padding: "20px" }} />
                                        <div className="carousel-text">
                                            <h3 className='caption-title' style={{ color: "white", fontSize: "14px" }}><span>PROJECT: </span>{project.title}</h3>
                                            <h4 className='caption-para' style={{ fontSize: "12px" }}><span>SCOPE: </span>{project.scope}</h4>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default Carousel;