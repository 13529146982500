import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import './downloads.css';
import ScrollToTopButton from '../ScrollButton';
function downloads() {
    return (

        <>
            <Helmet>
                <title>Acoustic Panels Catalogue - Acoustical Surfaces</title>
                <meta name="description" content=" " />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Acoustic Panels Catalogue - Acoustical Surfaces" />
                <meta property="og:description" content=" " />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/downloads" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/downloads" />
            </Helmet>
            <Header />
            <section className="content" id="downloads">
                <div className="container">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item catho">
                                <a className="nav-link active show" data-toggle="tab" href="#home">CATALOGUE</a>
                            </li>
                            <li className="nav-item catho">
                                <a className="nav-link data" data-toggle="tab" href="#menu1">DATA SHEET</a>
                            </li>
                            <li className="nav-item catho">
                                <a className="nav-link" data-toggle="tab" href="#menu3">TEST REPORT</a>
                            </li>
                            <li className="nav-item catho">
                                <a className="nav-link data" data-toggle="tab" href="#menu4">COLOR CHARTS</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div id="home" className="tab-pane active show"><br />
                                <div className='catfaq'>
                                    <input id='catfaq-a' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/fabrisorb/fabrisorb-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>01. FABRISORB</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-b' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/fabrisorb-deco/fabrisorb-deco-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>02. FABRISORB - DECO</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-c' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/echo-eliminator/echo-eliminator-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>03. ECHO ELIMINATOR</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-d' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/mlvb/noise-stop-vinyl-barrier-mlvb-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>04. MLVB</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-e' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/wood-wool/envirocoustic-wood-wool-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>05. WOOD WOOL</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-f' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/decopanel/decopanel-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>06. DECOPANEL</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-g' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/fabriwall/fabriwall-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>07. FABRIWALL</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-i' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/woodtec/woodtec-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>08. WOODTEC</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-j' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/foam-stop-polyurethane/foam-stop-polyurethane-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>09. FOAM STOP POLYURETHANE</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-k' type='checkbox' />
                                    <label for='catfaq-k'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>10. DIFFUSO</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/diffuso/diffuso-prd-catalogue.pdf" className='linkdec' target="_blank">Diffuso-PRD-Catalogue</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/diffuso/diffuso-qrd-wooden-diffuser-catalogue.pdf" className='linkdec' target="_blank">Diffuso-QRD Wooden Diffuser-Catalogue</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/diffuso/diffuso-wooden-diffuser-catalogue.pdf" className='linkdec' target="_blank">DIFFUSO-Wooden Diffuser-Catalogue</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-l' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polymax/polymax-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>11. POLYMAX</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-m' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polysorb/polysorb-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>12. POLYSORB</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-n' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polysorb-deco/polysorb-deco-brochure.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>13. POLYSORB-DECO</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-o' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/star-home-cinema/star-home-cinema-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>14. STAR HOME CINEMA</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-p' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/ecotherm/ecotherm-catalogue.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>15. ECOTHERM</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-q' type='checkbox' />
                                    <label for='catfaq-q'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>16. DECOWOOD</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="/pdfs/DECOWOOD.pdf" className='linkdec' target="_blank">DECOWOOD</Link></li>
                                                {/* <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decowood/decowood-acoustic-pet-wood-panel.pdf" className='linkdec' target="_blank">DECOWOOD - Acoustic PET Wood Panel</Link></li>
                                        <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/catalogue/decowood/decowood-grooved-pet-wood-panel.pdf" className='linkdec' target="_blank">DECOWOOD - Grooved PET Wood Panel</Link></li> */}
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-r' type='checkbox' />
                                    {/* <label>
                            <div className='catfaq-arrow'></div>
                            <Link to="https://www.acousticalsurfaces.in/catalogue/foamwood/foamwood-acoustic-pu-wood-panel.pdf" className='linkdec' target="_blank">
                                <div className="featured-products">
                                    <h4>17. FOAMWOOD</h4>
                                </div>
                            </Link>
                            </label>
                            <input id='catfaq-s' type='checkbox' />
                            <label>
                            <div className='catfaq-arrow'></div>
                            <Link to="https://www.acousticalsurfaces.in/catalogue/polywood/polywood-acoustic-polyester-wood-panel.pdf" className='linkdec' target="_blank">
                                <div className="featured-products">
                                    <h4>18. POLYWOOD</h4>
                                </div>
                            </Link>
                            </label> */}
                                    <input id='catfaq-t' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/acousty-door/asi-soundproof-door.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>17. ACOUSTY DOOR</h4>
                                            </div>
                                        </Link>
                                    </label>
                                    <input id='catfaq-t' type='checkbox' />
                                    <label>
                                        <div className='catfaq-arrow'></div>
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/asi-knauf-danoline-kraft/asi-knauf-danoline-kraft.pdf" className='linkdec' target="_blank">
                                            <div className="featured-products">
                                                <h4>18. ASI-KNAUF DANOLINE KRAFT</h4>
                                            </div>
                                        </Link>
                                        
                                    </label>
                                </div>
                            </div>
                            <div id="menu1" className="tab-pane fade"><br />
                                <div className='catfaq'>
                                    <input id='catfaq-aa' type='checkbox' />
                                    <label for='catfaq-aa'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>01. FABRISORB</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb/fabrisorb-acoustical-clouds.pdf" className='linkdec' target='_blank'>Fabrisorb - Acoustical Clouds</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb/noise-stop-fabrisorb-baffles.pdf" className='linkdec' target='_blank'>Noise S.T.O.P Fabrisorb - Baffles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb/noise-stop-fabrisorb-ceiling-tiles.pdf" className='linkdec' target='_blank'>Noise S.T.O.P Fabrisorb - Ceiling Tiles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb/noise-stop-fabrisorb-designer-panels.pdf" className='linkdec' target='_blank'>Noise S.T.O.P Fabrisorb - Designer Panels</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb/noise-stop-fabrisorb-wall-panels.pdf" className='linkdec' target='_blank'>Noise S.T.O.P Fabrisorb - Wall Panels</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ab' type='checkbox' />
                                    <label for='catfaq-ab'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>02. FABRISORB-DECO</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb-deco/fabrisorb-deco-acoustic-wall-panels.pdf" className='linkdec' target='_blank'>Fabrisorb Deco - Acoustical Wall Panels</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb-deco/fabrisorb-deco-baffles.pdf" className='linkdec' target='_blank'>Fabrisorb DECO - Baffles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb-deco/fabrisorb-deco-clouds.pdf" className='linkdec' target='_blank'>Fabrisorb DECO - Clouds</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabrisorb-deco/fabrisorb-deco-designer-tiles.pdf" className='linkdec' target='_blank'>Fabrisorb DECO - Designer Tiles</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ac' type='checkbox' />
                                    <label for='catfaq-ac'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>03. ECHO ELIMINATOR</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/echo-eliminator/echo-eliminator.pdf" className='linkdec' target='_blank'>ECHO ELIMINATOR</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ad' type='checkbox' />
                                    <label for='catfaq-ad'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>04. MLVB</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/mlvb/mass-loaded-vinyl-barrier-mlvb.pdf" className='linkdec' target='_blank'>Mass Loaded Vinyl Barrier (MLVB)</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ae' type='checkbox' />
                                    <label for='catfaq-ae'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>05. WOOD WOOL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/wood-wool/asi-envirocoustic-wood-wool-tiles-baffles.pdf" className='linkdec' target='_blank'>ASI-Envirocoustic Wood Wool - Tiles & Baffles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/wood-wool/asi-envirocoustic-wood-wool-wall-ceiling-panels.pdf" className='linkdec' target='_blank'>ASI-Envirocoustic Wood Wool - Wall & Ceiling Panels</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/wood-wool/envirocoustic-wood-wool-datasheet.pdf" className='linkdec' target='_blank'>Envirocoustic Wood Wool - DataSheet</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-af' type='checkbox' />
                                    <label for='catfaq-af'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>06. DECOPANEL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/datasheet-cloud.pdf" className='linkdec' target='_blank'>Datasheet - Cloud</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/datasheet-plain.pdf" className='linkdec' target='_blank'>Datasheet - Plain</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/datasheet-print-design.pdf" className='linkdec' target='_blank'>Datasheet - Print Design</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/datasheet-print-rolls.pdf" className='linkdec' target='_blank'>Datasheet - Print Rolls</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/datasheet-tiles.pdf" className='linkdec' target='_blank'>Datasheet - Tiles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/decopanel-baffles.pdf" className='linkdec' target='_blank'>Decopanel - Baffles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decopanel/decopanel-grooved-design.pdf" className='linkdec' target='_blank'>Decopanel - Grooved Design</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ag' type='checkbox' />
                                    <label for='catfaq-ag'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>07. FABRIWALL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabriwall/cfab-fabriwall.pdf" className='linkdec' target='_blank'>CFAB - FABRIWALL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabriwall/echo-eliminator-fabriwall.pdf" className='linkdec' target='_blank'>ECHO ELIMINATOR - FABRIWALL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabriwall/fiber-board-fabriwall.pdf" className='linkdec' target='_blank'>FIBER BOARD - FABRIWALL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabriwall/foam-stop-polyurethane-fabriwall.pdf" className='linkdec' target='_blank'>FOAM STOP POLYURETHANE - FABRIWALL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/fabriwall/polymax-fabriwall.pdf" className='linkdec' target='_blank'>POLYMAX - FABRIWALL</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ai' type='checkbox' />
                                    <label for='catfaq-ai'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>08. WOODTEC</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/woodtec/woodtec-designer-acoustic-panel.pdf" className='linkdec' target='_blank'>WOODTEC-DESIGNER ACOUSTIC PANEL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/woodtec/woodtec-linear-acoustic-panel.pdf" className='linkdec' target='_blank'>WOODTEC-LINEAR ACOUSTIC PANEL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/woodtec/woodtec-perforated-acoustic-panel.pdf" className='linkdec' target='_blank'>WOODTEC-PERFORATED ACOUSTIC PANEL</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/woodtec/woodtec-slotted-acoustic-panel.pdf" className='linkdec' target='_blank'>WOODTEC-SLOTTED ACOUSTIC PANEL</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-aj' type='checkbox' />
                                    <label for='catfaq-aj'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>09. FOAM STOP POLYURETHANE</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/asi-usa-foam-stop-pyramid.pdf" className='linkdec' target='_blank'>ASI-USA-Foam STOP Pyramid</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/bass-trap-foam-stop-polyurethane-datasheet.pdf" className='linkdec' target='_blank'>Bass Trap-Foam S.T.O.P Polyurethane - Data Sheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/cylindrical-design-foam-stop-polyurethane-datasheet.pdf" className='linkdec' target='_blank'>Cylindrical Design-Foam S.T.O.P Polyurethane - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/eggcrate-panels-foam-stop-polyurethane-datasheet.pdf" className='linkdec' target='_blank'>Eggcrate Panels-Foam S.T.O.P Polyurethane - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/foam-stop-polyurethane-datasheet.pdf" className='linkdec' target='_blank'>Foam S.T.O.P Polyurethane - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/foam-stop-polyurethane-plain-datasheet.pdf" className='linkdec' target='_blank'>Foam S.T.O.P Polyurethane-Plain - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foam-stop-polyurethane/pyramid-design-foam-stop-polyurethane-datasheet.pdf" className='linkdec' target='_blank'>Pyramid Design-Foam S.T.O.P Polyurethane - Datasheet</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ak' type='checkbox' />
                                    <label for='catfaq-ak'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>10. DIFFUSO</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/diffuso/diffuso-prd.pdf" className='linkdec' target='_blank'>Diffuso-PRD</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/diffuso/diffuso-qrd-wooden-diffuser.pdf" className='linkdec' target='_blank'>Diffuso-QRD Wooden Diffuser</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-al' type='checkbox' />
                                    <label for='catfaq-al'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>11. POLYMAX</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polymax/polymax-acoustic-ceiling-tiles-15mm.pdf" className='linkdec' target='_blank'>POLYMAX-Acoustic Ceiling Tiles - 15 mm</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polymax/polymax-acoustic-ceiling-tiles-50mm.pdf" className='linkdec' target='_blank'>POLYMAX-Acoustic Ceiling Tiles - 50mm</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polymax/polymax-ceiling-and-wall-usa.pdf" className='linkdec' target='_blank'>PolyMax-Ceiling and Wall-USA</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-am' type='checkbox' />
                                    <label for='catfaq-am'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>12. POLYSORB</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb/polysorb-baffles-datasheet.pdf" className='linkdec' target='_blank'>POLYSORB Baffles - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb/polysorb-clouds-datasheet.pdf" className='linkdec' target='_blank'>POLYSORB Clouds - Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb/polysorb-wall-panels-datasheet.pdf" className='linkdec' target='_blank'>POLYSORB Wall Panels - Datasheet</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-an' type='checkbox' />
                                    <label for='catfaq-an'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>13. POLYSORB-DECO</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-acoustic-wall-panels.pdf" className='linkdec' target='_blank'>Polysorb-Deco Acoustic Wall Panels</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-baffles.pdf" className='linkdec' target='_blank'>Polysorb-DECO- Baffles</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-clouds.pdf" className='linkdec' target='_blank'>Polysorb-DECO- Clouds</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ao' type='checkbox' />
                                    <label for='catfaq-ao'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>14. STAR HOME CINEMA</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/star-home-cinema/star-home-cinema-catalogue.pdf" className='linkdec' target='_blank'>STAR HOME CINEMA - Catalogue</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ap' type='checkbox' />
                                    <label for='catfaq-ap'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>15. ECOTHERM</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/ecotherm/ecotherm-catalogue.pdf" className='linkdec' target='_blank'>ECOTHERM - Catalogue</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-aq' type='checkbox' />
                                    <label for='catfaq-aq'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>16. DECOWOOD</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decowood/decowood-acoustic-pet-wood-panel.pdf" className='linkdec' target='_blank'>DECOWOOD - Acoustic PET Wood Panel</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/decowood/decowood-grooved-pet-wood-panel.pdf" className='linkdec' target='_blank'>DECOWOOD - Grooved PET Wood Panel</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-ar' type='checkbox' />
                                    <label for='catfaq-ar'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>17. FOAMWOOD</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foamwood/foamwood-acoustic-pu-wood-panel.pdf" className='linkdec' target='_blank'>FOAMWOOD - Acoustic PU Wood Panel</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-as' type='checkbox' />
                                    <label for='catfaq-as'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>18. POLYWOOD</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/foamwood/foamwood-acoustic-pu-wood-panel.pdf" className='linkdec' target='_blank'>Polywood</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-at' type='checkbox' />
                                    <label for='catfaq-at'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>19. ACOUSTYDOOR</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/acoustydoor/asi-soundproof-door.pdf" className='linkdec' target='_blank'>ASI SOUNDPROOF DOOR</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-au' type='checkbox' />
                                    <label for='catfaq-au'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>20. ASI-KNAUFDANOLINE KRAFT</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/asi-knaufdanoline-kraft/asi-knauf-danoline-kraft.pdf" className='linkdec' target='_blank'>ASI Knauf Danoline Kraft</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-av' type='checkbox' />
                                    <label for='catfaq-av'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>21. SOUND ISOLATION CLIP</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/sound-isolation-clip/sound-clip-sc101-datasheet.pdf" className='linkdec' target='_blank'>SOUND CLIP-SC101 Datasheet</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/datasheet/sound-isolation-clip/sound-clip-sc102-datasheet.pdf" className='linkdec' target='_blank'>SOUND CLIP-SC102 Datasheet</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div id="menu3" className="tab-pane fade"><br />
                                <div className='catfaq'>
                                    <input id='catfaq-ba' type='checkbox' />
                                    <label for='catfaq-ba'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>01. FABRISORB</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabrisorb/test-report-asi-fabrisorb-20mm-sound-absorption.pdf" className='linkdec' target='_blank'>Test Report ASI Fabrisorb 20mm Sound Absorption</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabrisorb/test-report-asi-fabrisorb-25mm-sound-absorption.pdf" className='linkdec' target='_blank'>Test Report ASI Fabrisorb 25mm Sound Absorption</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabrisorb/test-report-asi-fabrisorb-40mm-sound-absorption.pdf" className='linkdec' target='_blank'>Test Report ASI Fabrisorb 40mm Sound Absorption</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bb' type='checkbox' />
                                    <label for='catfaq-bb'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>02. FABRISORB-DECO</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabrisorb-deco/test-report-fabrisorb-deco-50mm-sound-absorption.pdf" className='linkdec' target='_blank'>Test Report FABRISORB DECO 50MM Sound Absorption</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bc' type='checkbox' />
                                    <label for='catfaq-bc'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>03. ECHO ELIMINATOR</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/echo-eliminator/asi-flame-spread-echo-eliminator-test-report.pdf" className='linkdec' target='_blank'>ASI FLAME SPREAD Echo Eliminator Test Report</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/echo-eliminator/asi-sound-absorption-echo-eliminator-test-report.pdf" className='linkdec' target='_blank'>ASI Sound Absorption Echo Eliminator Test Report</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/echo-eliminator/rohs-test-echo-eliminator.pdf" className='linkdec' target='_blank'>RoHS TEST ECHO ELIMINATOR</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bg' type='checkbox' />
                                    <label for='catfaq-bg'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>04. DECOPANEL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decopanel/test-report-decopanel-baffles-50mm.pdf" className='linkdec' target='_blank'>Test Report - DECOPANEL BAFFLES 50MM</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decopanel/test-report-decopanel-10mm-with-50mm-airgap.pdf" className='linkdec' target='_blank'>Test Report - DECOPANEL 10MM with 50MM Airgap</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decopanel/test-report-decopanel-10mm.pdf" className='linkdec' target='_blank'>Test Report - DECOPANEL 10MM</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decopanel/test-report-sound-absorption-12mm-decopanel-with-airgap.pdf" className='linkdec' target='_blank'>Test Report - Sound Absorption 12MM Decopanel with AIRGAP</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decopanel/test-report-sound-absorption-12mm-decopanel.pdf" className='linkdec' target='_blank'>Test Report - Sound Absorption 12MM Decopanel</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bh' type='checkbox' />
                                    <label for='catfaq-bh'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>05. FABRIWALL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabriwall/asi-flame-spread-echo-eliminator-test-report.pdf" className='linkdec' target='_blank'>ASI FLAME SPREAD Echo Eliminator Test Report</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabriwall/asi-sound-absorption-echo-eliminator-test-report.pdf" className='linkdec' target='_blank'>ASI Sound Absorption Echo Eliminator Test Report</Link></li>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/fabriwall/test-report-sound-absorption-asi-fabriwall.pdf" className='linkdec' target='_blank'>Test Report Sound Absorption ASI FABRIWALL</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bj' type='checkbox' />
                                    <label for='catfaq-bj'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>06. WOODTEC</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/woodtec/test-report-woodtec-microperforated-wooden-acoustic-panel.pdf" className='linkdec' target='_blank'>Test Report WOODTEC Microperforated Wooden Acoustic Panel</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bk' type='checkbox' />
                                    <label for='catfaq-bk'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>07. POLYMAX</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/polymax/polymax-surface-spread-of-flame-test-report.pdf" className='linkdec' target='_blank'>POLYMAX SURFACE SPREAD OF FLAME-TEST REPORT</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-bl' type='checkbox' />
                                    <label for='catfaq-bl'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>08. DECOWOOD</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/test-reports/decowood/test-report-sound-absorption-asi-decowood.pdf" className='linkdec' target='_blank'>TEST REPORT Sound Absorption ASI DECOWOOD</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div id="menu4" className="tab-pane fade"><br />
                                <div className='catfaq'>
                                    <input id='catfaq-ca' type='checkbox' />
                                    <label for='catfaq-ca'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>01. DECOPANEL</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/color-chart/decopanel/decopanel-color-chart.pdf" className='linkdec' target='_blank'>Decopanel Color Chart</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-cb' type='checkbox' />
                                    <label for='catfaq-cb'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>02. FABRIC COLOR SHADE</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/color-chart/fabrisorb/acousty-fabric-color-chart.pdf" className='linkdec' target='_blank'>Acoustyfabric Color Chart</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                    <input id='catfaq-cc' type='checkbox' />
                                    <label for='catfaq-cc'>
                                        <div className='catfaq-arrow'></div>
                                        <div className="featured-products">
                                            <h4>03. DECO-FELT</h4>
                                            <ul>
                                                <li><i className="fa fa-file-pdf-o" aria-hidden="true"></i><Link to="https://www.acousticalsurfaces.in/color-chart/decofelt/decofelt-color-chart.pdf" className='linkdec' target='_blank'>Deco-Felt Color Chart</Link></li>
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default downloads; 