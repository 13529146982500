import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import '../Project/project.css';
import ScrollToTopButton from '../ScrollButton';
import Amaryllis1 from '../../images/Project_Images/Amaryllis_1.jpeg';
import Amaryllis2 from '../../images/Project_Images/Amaryllis_2.jpeg';
import Amaryllis3 from '../../images/projects-slider/amaryllis-3.webp';
import Flocert1 from '../../images/Project_Images/Flocert_1.jpeg';
import Flocert2 from '../../images/projects-slider/flocert-2.webp';
import Flocert3 from '../../images/projects-slider/flocert-1.webp';
// import Hometheater1 from '../../images/projects-slider/ht-mangalore-1.webp';
// import Hometheater2 from '../../images/projects-slider/ht-mangalore-2.webp';
// import Hometheater3 from '../../images/projects-slider/ht-mangalore-3.webp';
import Sabic1 from '../../images/Project_Images/Sabic_1.jpg';
import Sabic2 from '../../images/projects-slider/sabic-bangalore.webp';
import Sabic3 from '../../images/Project_Images/Sabic_3.jpg';
import DRC1 from '../../images/Project_Images/drc-1.jpeg';
import DRC2 from '../../images/Project_Images/DRC_2.jpg';
import DRC3 from '../../images/projects-slider/drc-mysore.webp';
import Hospira1 from '../../images/Project_Images/Hospira_1.jpg';
import Hospira2 from '../../images/Project_Images/hospira_2.jpg';
import Hospira3 from '../../images/projects-slider/hospira-chennai.webp';
import CMC1 from '../../images/Project_Images/cmc_building.jpeg';
import CMC2 from '../../images/Project_Images/cmc-2.jpg';
import CMC3 from '../../images/Project_Images/cmc_3.avif';
import KIS1 from '../../images/Project_Images/KISchool_1.jpg';
import KIS2 from '../../images/projects-slider/proj-kodai-2.webp';
import KIS3 from '../../images/projects-slider/proj-kodai-3.webp';
import Generalmotor1 from '../../images/Project_Images/GM_Building.webp';
import Generalmotor2 from '../../images/Project_Images/GM_2.webp';
import Generalmotor3 from '../../images/projects-slider/gm-bangalore.webp';
import DPS1 from '../../images/Project_Images/DPSchool_1.jpg';
import DPS2 from '../../images/Project_Images/DPSchool_2.jpg';
import DPS3 from '../../images/Project_Images/DPSchool_3.webp';
import Navelbase1 from '../../images/Project_Images/Navelbase_1.jpg';
import Navelbase2 from '../../images/projects-slider/proj_13.webp';
import Navelbase3 from '../../images/projects-slider/proj_13.webp';
import Zoho1 from '../../images/Project_Images/Zoho_1.jpeg';
import Zoho2 from '../../images/projects-slider/zoho-chennai.webp';
import Zoho3 from '../../images/Project_Images/zoho_3.webp';
import Twitter1 from '../../images/Project_Images/twitter_building.jpeg';
import Twitter2 from '../../images/projects-slider/twitter-india.webp';
import Twitter3 from '../../images/Project_Images/Twitter_3.jpg';
import Wipro1 from '../../images/Project_Images/wipro_1.webp';
import Wipro2 from '../../images/new_pro_img/wipro_2.jpg';
import Wipro3 from '../../images/Project_Images/wipro_3.webp';
import Goldman1 from '../../images/Project_Images/Goldman_building.webp';
import Goldman2 from '../../images/Project_Images/Goldman_4.jpeg';
import Goldman3 from '../../images/projects-slider/goldman-bangalore.webp';
// import EIBFS1 from '../../images/Project_Images/EMIRATES_1.jpg';
// import EIBFS2 from '../../images/Project_Images/Emirates_2.jpg';
// import EIBFS3 from '../../images/Project_Images/Emirates_3.webp';
import MoF1 from '../../images/Project_Images/Musium_Furute_1.webp';
import MoF2 from '../../images/Project_Images/Museum_2.jpg';
import MoF3 from '../../images/Project_Images/Musium_3.jpeg';
import Louvre1 from '../../images/Project_Images/Louvre_1.jpg';
import Louvre2 from '../../images/Project_Images/Louvre-2.jpg';
import Louvre3 from '../../images/Project_Images/Louvre_3.jpg';
import Palace1 from '../../images/Project_Images/Presidential_1.jpg';
import Palace2 from '../../images/Project_Images/Presidential_2.webp';
import Palace3 from '../../images/Project_Images/Presidential_3.jpg';
import KAPSARC1 from '../../images/Project_Images/Kapsarc_1.webp';
import KAPSARC2 from '../../images/Project_Images/Kapsarc_2.jpg';
import KAPSARC3 from '../../images/Project_Images/Kapsarc_3.jpg';
import Ferrari1 from '../../images/Project_Images/ferrari_1.jpeg';
import Ferrari2 from '../../images/Project_Images/ferrari_2.jpeg';
import Ferrari3 from '../../images/Project_Images/ferrari_3.jpeg';
import Bosch1 from '../../images/projects-slider/bosch.webp';
import Bosch2 from '../../images/projects-slider/bosch-bangalore.webp';
import Bosch3 from '../../images/projects-slider/bosch-meeting-hall.webp';
import Teleperformance1 from '../../images/projects-slider/project-teleperformance1.webp';
import Teleperformance2 from '../../images/projects-slider/project-teleperformance2.webp';
import Teleperformance3 from '../../images/projects-slider/project-teleperformance3.webp';
import Bhutan1 from '../../images/Project_Images/GCIT_Build.jpg';
import Bhutan2 from '../../images/bhutanmain.jpeg';
import Bhutan3 from '../../images/bhutan3.jpg';
import Biocon1 from '../../images/Project_Images/biocon_Build.jpg';
import Biocon2 from '../../images/Project_Images/biocon.jpeg';
import Biocon3 from '../../images/biocon2.jpeg';
import Epam1 from '../../images/Epam.jpeg';
import Epam2 from '../../images/Project_Images/epam_1.jpeg';
import Epam3 from '../../images/Project_Images/epam_2.jpeg';
import hdfc1 from '../../images/hdfc.jpeg';
import hdfc2 from '../../images/hdfc2.jpeg';
import hdfc3 from '../../images/hdfc3.jpeg';
import rio1 from '../../images/rio-1.jpg';
import rio3 from '../../images/rio5.jpeg';
import rio2 from '../../images/rio4.png';
import myntra1 from '../../images/Project_Images/myntra_build.jpeg';
import myntra2 from '../../images/myntra2.jpeg'
import myntra3 from '../../images/myntra.jpeg';
import ipop1 from '../../images/ipopaudio.jpeg';
import ipop2 from '../../images/ipop2.jpg';
import ipop3 from '../../images/ipop3.jpg';
import library1 from '../../images/kalaignar1.jpg';
import library2 from '../../images/kalaignar2.jpg';
import library3 from '../../images/kalaignar3.jpeg';
import dlf1 from '../../images/Project_Images/DLF_Build.webp';
import dlf2 from '../../images/dlf3.jpeg';
import dlf3 from '../../images/dlf2.jpeg';
import HTR1 from '../../images/Project_Images/HTR1.jpg';
import HTR2 from '../../images/Project_Images/HTR2.jpg';
import HTR3 from '../../images/Project_Images/HTR3.jpeg';
import Bajaj1 from '../../images/Project_Images/Bajaj_1.jpg';
import Bajaj2 from '../../images/Project_Images/Bajaj_Plaster_2.png';
import Bajaj3 from '../../images/Project_Images/Bajaj_Plaster_3.jpeg';
import Mallik1 from '../../images/Project_Images/Mallik_1.png';
import Mallik2 from '../../images/Project_Images/Mallik_2.jpeg';
import Mallik3 from '../../images/Project_Images/Mallik_3.jpeg';
import Ford1 from '../../images/Project_Images/Ford_1.jpg';
import Ford2 from '../../images/Project_Images/Ford_2.jpeg';
import Ford3 from '../../images/Project_Images/Ford_3.jpeg';
import IAS1 from '../../images/Project_Images/IAS_1.jpeg';
import IAS2 from '../../images/Project_Images/IAS_2.jpeg';
import IAS3 from '../../images/Project_Images/IAS_3.jpeg';
import DEC1 from '../../images/Project_Images/DEC_1.jpeg';
import DEC2 from '../../images/Project_Images/DEC_2.jpeg';
import DEC3 from '../../images/Project_Images/DEC_3.jpeg';
import KIMA1 from '../../images/Project_Images/KIMA_1.jpeg';
import KIMA2 from '../../images/Project_Images/KIMA_2.jpg';
import KIMA3 from '../../images/Project_Images/KIMA_3.jpg';
import Herbalife1 from '../../images/Project_Images/Herbalife_1.avif';
import Herbalife2 from '../../images/Project_Images/Herbalife_2.jpg';
import Herbalife3 from '../../images/Project_Images/Herbalife_3.jpg';
import Siemens1 from '../../images/Project_Images/Seimens_1.jpeg';
import Siemens2 from '../../images/Project_Images/Seimens_2.jpg';
import Siemens3 from '../../images/Project_Images/Seimens_3.png';
import Villa1 from '../../images/Project_Images/Villa_1.jpeg';
import Villa2 from '../../images/Project_Images/Villa_2.jpg';
import Villa3 from '../../images/Project_Images/Villa_3.jpg';
import Recca1 from '../../images/Project_Images/reca_club_1.png';
import Recca2 from '../../images/Project_Images/reca_club_2.jpeg';
import Recca3 from '../../images/Project_Images/reca_club_3.jpeg';
import Restobar1 from '../../images/Project_Images/Restobar_1.jpg';
import Restobar2 from '../../images/Project_Images/Restobar_2.jpeg';
import Restobar3 from '../../images/Project_Images/Restobar_3.jpg';

const projectsData = [
    {
        id: 42,
        name: "Restobar",
        location: "Tamilnadu",
        client: "Cubix Marketing",
        architect: "Ragavi Architects",
        aboutText: `Situated in Tamil Nadu, this restobar offers a perfect blend of modern ambiance and delectable cuisine. With a diverse menu featuring a mix of North Indian, South Indian, and Continental dishes, it caters to a wide range of tastes. The restobar also boasts a well-stocked bar, live music, and a cozy seating arrangement, making it an ideal spot for social gatherings, romantic dinners, and casual hangouts.`,
        scope: "Our scope for this project involved the supply and installation of printed Decopanels in collaboration with Cubix Marketing. These panels were selected to enhance the visual appeal and acoustic performance of the space, creating a unique and inviting atmosphere.",
        images: [Restobar1, Restobar2, Restobar3],
    },
    {
        id: 41,
        name: "Recca Club",
        location: "Kochi",
        client: "Recca Club",
        aboutText: `Nestled in the serene valley of Kakkanad, Recca Club Kochi is a premier family club spread over 3.5 acres. Offering world-class amenities, including a luxurious swimming pool, a well-equipped gym, indoor games, and a variety of dining options, Recca Club is the perfect destination for relaxation and recreation by hosting year-round events and activities, making it a vibrant hub for families and individuals alike.`,
        scope: "We supplied an exclusive range of Decopanel printed and plain series, al ong with Decowood panels. These high-quality elements was carefully selected to elevate the aesthetic appeal and functionality of Recca Club’s interiors and also to ensure it remains a premier destination for the members.",
        images: [Recca1, Recca2, Recca3],
    },
    {
        id: 40,
        name: "Dua Villa",
        location: "Mumbai",
        client: "",
        architect: "",
        aboutText: `For any home theatre project, ASI aims to create an immersive, high-quality acoustic experience. This state-of-the-art installation will transform the space into a hub of entertainment. Perfect for enjoying movies, music, and more, we deliver superior sound clarity, enhancing the overall experience with top-tier acoustic treatments. Through our expertise, we deliver tailored solutions.`,
        scope: "In the luxurious setting of DUA Villa, we embarked on a mission to elevate the home theatre experience to new heights with supply and installation of Decowood panels, an acoustic door, and Fabriwall, where each element chosen to enhance both aesthetics and sound quality.",
        images: [Villa1, Villa2, Villa3],
    },
    {
        id: 39,
        name: "Kings International Medical Academy (KIMA)- Auditorium",
        location: "Chennai",
        client: "Cubix Marketing",
        architect: "",
        aboutText: `Kings International Medical Academy (KIMA) has been a frontrunner, an epitome of medical education based in the beautiful southern city of India, Mamallapuram. From counseling, guidance, and admission to MBBS, Kings International Medical Academy opened new doors of opportunities for students to avail high-quality education at a very low cost and also provides best possible medical training.`,
        scope: "Our scope for this project involves the supply of Fabrisorb panels. These panels are designed to optimize the acoustic environment within the auditorium, ensuring that the spaces are conducive to clear and effective communication.",
        images: [KIMA1, KIMA2, KIMA3],
    },
    {
        id: 38,
        name: "IAS Officers Club, AP",
        location: "Vijayawada",
        client: "Cubix Marketing",
        architect: "",
        aboutText: `The IAS Officers Club in Vijayawada stands as a premier venue designed to meet the professional and recreational needs of IAS officers. Our recent project involved enhancing the acoustic environment of the club to ensure optimal sound quality and a lively atmosphere. Our efforts have transformed the club into a harmonious haven, perfect for meetings, events, and social gatherings.`,
        scope: "Our project scope included the supply and installation of Fabriwall and WoodTec systems. Our skilled team carried out the project with precision and creativity, turning the club into an ideal venue for various functions and gatherings.",
        images: [IAS1, IAS2, IAS3],
    },
    {
        id: 37,
        name: "KAPSARC",
        location: "Riyadh, Saudi Arabia",
        client: "Saudi Aramco",
        architect: "Zaha Hadid",
        aboutText: `An independent non-profit organization dedicated to researching economics and technology across all platforms of energy. Designed by Zaha Hadid Architects, the modular structure is a living, organic scheme. The exterior’s shell-like façades conceal the porous interior, with sheltered courtyards bringing natural daylight into the center of the scheme. Construction Area: 66,000m2; Site Area: 530,000 m2; Footprint Area: 28,500 m2`,
        scope: "We successfully executed a project of this size and complexity where our scope was supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions.",
        images: [KAPSARC1, KAPSARC2, KAPSARC3],
    },
    {
        id: 36,
        name: "Presidential Palace",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The location of the new Presidential Palace is in Ras Al Akhdar peninsula adjacent to the Emirates Palace Hotel, Abu Dhabi. The development will encompass approximately 150 hectares, and will include the Palace and the required ancillary buildings. The Palace will exceed 160000m2 of built area and will contain several VIP wings, a Ministers wing, and several Majlis and Dining Halls.`,
        scope: "Supply & Installation of acoustical Wall panels. It was a great opportunity to be part of this iconic project.",
        images: [Palace1, Palace2, Palace3],
    },
    {
        id: 35,
        name: "Louvre",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `Abu Dhabi’s Tourism Development & Investment Company (TDIC) has completed the installation of the dome structure in its upcoming attraction Louvre Abu Dhabi, Designed by architect Jean Nouvel. Louvre Abu Dhabi encompass 9,200 square metres of art galleries including a 6,681 sqm Permanent Gallery and a 2,364 sqm Temporary Gallery.`,
        scope: "We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000SQM of acoustic wood wool.",
        images: [Louvre1, Louvre2, Louvre3],
    },
    {
        id: 34,
        name: "The Museum of Future",
        location: "Dubai, UAE",
        client: "",
        architect: "",
        aboutText: `The Museum of the Future is a visionary cultural institution currently under construction in Dubai, a showplace for a new era - a center of creativity and hope where you can see, touch and shape our shared future.`,
        scope: "Acoustical Plaster ceiling in Auditorium below the walkway & Hall area.",
        images: [MoF1, MoF2, MoF3],
    },
    {
        id: 33,
        name: "Ferrari World",
        location: "Abu Dhabi, UAE",
        client: "",
        architect: "",
        aboutText: `The 86,000 m2 enclosed area of Ferrari World Abu Dhabi can fit 7 football fields in 'head to toe’. The total roof area of 200,000 m2 uses enough aluminum to cover 16,750 Ferraris, or if you laid the roof flat you could place 20,100 Ferrari's side by side and end to end. If Ferrari World Abu Dhabi was turned upright, it would be the tallest man made structure in the world at over 300 floors!`,
        scope: "We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation.",
        images: [Ferrari1, Ferrari2, Ferrari3],
    },
    // {
    //     id: 16,
    //     name: "Emirates Institute of Banking & Financial Studies",
    //     location: "Dubai, UAE",
    //     client: "",
    //     architect: "",
    //     aboutText: `Emirates Institute For Banking and Financial Studies (EIBFS) has made quantum leaps by delivering world className education, training and allied services in the area of Banking and Finance at its three campuses strategically located at Sharjah, Abu Dhabi and Dubai.`,
    //     scope: "Acoustical Interior fit out which include In-house acoustic consultancy & Design, Acoustical wall treatment & ceiling.",
    //     images: [EIBFS1, EIBFS2, EIBFS3],
    // },
    {
        id: 32,
        name: "Mallik Multitechnologies",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Mallik Multitechnologies Private Limited is a non-government company, incorporated on May 19, 2011. It's a private, unlisted company classified as a 'company limited by shares'. Mallik Multitechnologies Private Limited has been majorly involved in manufacturing machinery and equipment for the past 13 years, and its operations are currently active. The company continues to innovate and expand its capabilities within the industry.`,
        scope: "Our work involves the supply and installation of Decopanel wall panels and acoustic doors. With our expertise we aimed at enhancing sound quality and provide sophisticated aesthetics for the project.",
        images: [Mallik1, Mallik2, Mallik3],
    },
    {
        id: 31,
        name: "Kalaignar Centenary Library",
        location: "MADURAI, TAMILNADU",
        client: "Dhanvi Enterprises",
        architect: "",
        aboutText: `The Kalaignar Centenary Library was inaugurated by Hon’ble Chief Minister Thiru M.K. Stalin on July 15, 2023. This impressive six-storey building spans a whopping 2.13 lakh square feet and is situated on 2.70 acres of land. It’s a true architectural marvel! The library boasts an array of features like Art Gallery, Multi-purpose Hall,Children’s Theatre, Conference Hall, Rare Collections.`,
        scope: " Supply and installation of Decopanel designer panels- a delightful fusion of aesthetics and acoustics- for the vibrant kids’ section and Fabriwall system- an elegant solution that highlights acoustic performance in the auditorium.",
        images: [library1, library2, library3],
    },
    {
        id: 30,
        name: "Biocon",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Biocon Limited is an Indian biopharmaceutical company based in Bangalore. It was founded by Kiran Mazumdar-Shaw in 1978. The company manufactures generic active pharmaceutical ingredients that are sold in approximately 120 countries, including the United States and Europe. Biocon is the first company worldwide to develop recombinant human insulin (rh-insulin) on a Pichia expression system.`,
        scope: "At Biocon Bangalore, we successfully completed a project for their board room, implementing the Fabriwall system to enhance the acoustic environment, ensuring a high-quality audio experience and improving the overall aesthetics of the space",
        images: [Biocon1, Biocon2, Biocon3],
    },
    {
        id: 29,
        name: "Home Theater, Rajapalayam",
        location: "Rajapalayam Tamilnadu",
        client: "Cubix Marketing",
        architect: "Mr. Sreekumar",
        aboutText: `For any home theatre project, ASI aims to create an immersive, high-quality acoustic experience. This state-of-the-art installation will transform the space into a hub of entertainment. Perfect for enjoying movies, music, and more, we deliver superior sound clarity, enhancing the overall experience with top-tier acoustic treatments. Through our expertise, we deliver tailored solutions.`,
        scope: "Our comprehensive home theater installation includes the supply and installation of Fabriwall for the walls and Decopanel for the ceiling. Our goal is to enhance and ensure a superior cinematic experience tailored to the client's needs.",
        images: [HTR1, HTR2, HTR3],
    },
    {
        id: 28,
        name: "Epam",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `EPAM India has fast grown to be a 7,100+ people organization across five locations, its a part of a global network of advisors, consultants, engineers, scientists and creatives who enable customers to be competitive and disruptive in the marketplace through innovative technology solutions while helping them to navigate successfully through multiple waves of technology change.`,
        scope: "Our project scope at EPAM included the supply and installation of Acoustic Plaster in the meeting room. This acoustic treatment was designed to optimize sound quality, ensuring a more effective and comfortable meeting environment.",
        images: [Epam1, Epam2, Epam3],
    },
    {
        id: 27,
        name: "HDFC Project",
        location: "Mumbai",
        client: "BNP Interiors",
        architect: "Mr. Nirav",
        aboutText: `HDFC Bank ResidentialTraining Centre: Nestled within Palava, a burgeoning township on the outskirts of Mumbai, this innovative centre combines training and residential spaces across 8 floors. Notably, it aspires to achieve both a platinum green building rating and a Health + Wellbeing certification. The centre exemplifies sustainable and healthy design in a rapidly growing urban area.`,
        scope: "Our well-trained technicians transformed the HDFC auditorium into an acoustic wonderland with FabriWall excellence. The before-and-after images speak volumes—where once there was monotony, now there’s vibrancy.",
        images: [hdfc1, hdfc2, hdfc3],
    },
    {
        id: 26,
        name: "GCIT College Auditorium",
        location: "Bhutan",
        client: "Royal Government of Bhutan",
        architect: "",
        aboutText: `Gyalpozhing College of Information Technology - GCIT is a fascinating institution that’s shaping the tech-savvy minds of tomorrow and it holds a special place as one of the integral colleges within the Royal University of Bhutan.  GCIT aims to be a leading institution in software technology and interactive design. It’s like a tech-savvy village where equations meet hiking trails.`,
        scope: "Supply and installation of Polysorb Wall Panels and Clouds. Our skilled technicians, armed with their creativity, transformed and ensured the auditorium that once echoed into a space of harmony and acoustically optimized environment.",
        images: [Bhutan1, Bhutan2, Bhutan3],
    },
    {
        id: 25,
        name: "Ipop Audio-Experience Centre",
        location: "Pondicherry",
        client: "Mr. Manikandan",
        architect: "",
        aboutText: `IPOP Audio is the go-to spot for home theatre enthusiasts, featuring a state-of-the-art experience center. Customers can explore the latest audio-visual technology and enjoy unparalleled sound quality. Committed to delivering immersive experiences, IPOP Audio has carved out its place as a leader in the home theatre industry, setting new standards for excellence.`,
        scope: "Assessment, Planning, Customisation and Supply of D'ecopanel to achieve a great appeal to acoustics as well as aesthetics. Our tailored approach delivered top-quality results, transforming their space effectively.",
        images: [ipop1, ipop2, ipop3],
    },
    {
        id: 24,
        name: "Google Rio",
        location: "Bangalore, Karnataka",
        client: "BNP Interiors",
        architect: "",
        aboutText: `This 12-storey development boasts some of India's largest single floor plates, housing over 7,000 employees. Each floor features self-sustainable yet connected communities, fostering interaction between employees and various teams. This design enhances collaboration and productivity, creating a dynamic and integrated workspace. The building's scale and design set a benchmark for large office spaces in India.`,
        scope: "For this project, our scope includes the supply of FOAMSTOP Polyurethane. This high-performance material ensures superior soundproofing and insulation. Trust us to deliver quality and efficiency with every installation.",
        images: [rio1, rio2, rio3],
    },
    {
        id: 23,
        name: "Myntra",
        location: "Bangalore, Karnataka",
        client: "Myntra Jabong India Pvt Ltd.",
        architect: "",
        aboutText: `Myntra stands as one of India's largest fashion e-commerce stores, offering a wide range of fashion and lifestyle products for men, women, and kids. It boasts high-quality clothes, branded footwear, and bags. Additionally, Myntra sells beauty and personal care items, as well as home and living accessories. Their diverse product range caters to every style and need, ensuring top-notch quality.`,
        scope: "Our scope included the installation of Fabrisorb panels and Clouds. With these exclusive  elements which are crucial in enhancing both the acoustic quality and visual appeal of the space, we aimed to create a dynamic atmosphere at MYNTRA.",
        images: [myntra1, myntra2, myntra3],
    },
    {
        id: 22,
        name: "DLF The Camellias",
        location: "Gurugram",
        client: "",
        architect: "",
        aboutText: `DLF Camellias on Golf Course Road, Gurgaon, is a premier luxury condominium project by DLF. This prestigious development offers premium residences in configurations of 4BHK, 5BHK, and 6BHK units, showcasing exquisite design and top-tier amenities. Residents can enjoy world-class facilities and an unmatched living experience. The project also emphasizes sustainable living with eco-friendly features.`,
        scope: "Our scope for the DLF Camellias project includes the supply and installation of Fabriwall Acoustic Plaster in the ceilings. It is designed to improve the acoustic environment and provide superior sound insulation, contributing to the overall luxury and comfort.",
        images: [dlf1, dlf2, dlf3],
    },
    {
        id: 21,
        name: "DEC Infrastructures",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `DEC Industries, headquartered in Bangalore, is a leading player in the field of precision manufacturing and industrial services. Founded in 1968, DEC Industries has grown significantly, contributing to some of India's most advanced manufacturing projects. The company is known for its commitment to innovation, quality, and sustainability, delivering top-tier industrial solutions across various sectors.`,
        scope: "Our mission for this project was to transform the office environment at DEC industries with top-notch sound insulation and acoustic doors. These upgrades were meticulously chosen to foster a quieter and more productive atmosphere.",
        images: [DEC1, DEC2, DEC3],
    },
    {
        id: 20,
        name: "Robert Bosch Engineering & Business Solutions Pvt.Ltd.",
        location: "Bangalore, India",
        client: "",
        architect: "",
        aboutText: `In India, Bosch established its first sales agency in 1922 in Kolkata and began manufacturing in 1951. Today, it has 18 manufacturing sites and seven development and application centers. With a 100-year legacy, Bosch India leads in Mobility Solutions, Industrial Technology, Consumer Goods, and Energy and Building Technology and hosts the largest development center outside Germany.`,
        scope: "Our scope at Bosch included the supply and installation of the Fabriwall Stretch Fabric Acoustic Wall Panel System in their board room. This system was designed to optimize the acoustic environment, enhancing clarity in communications and aesthetic appeal of the space.",
        images: [Bosch1, Bosch2, Bosch3],
    },
    {
        id: 19,
        name: "Teleperformance",
        location: "INDORE, MP",
        client: "Brilliant",
        architect: "",
        aboutText: `Teleperformance SE, headquartered in France, stands as a global leader in omnichannel customer experience management. Spanning operations in 91 countries, they excel in seamless customer interactions across diverse markets. From customer acquisition to technical support, debt collection, and social media services, Teleperformance leaves no stone unturned in delivering exceptional experiences.`,
        scope: "In this dynamic setting we supplied and expertly installed D’ecopanel—an acoustic marvel that transcends mere functionality. D’ecopanel seamlessly blends aesthetics and sound control, transforming spaces into havens of tranquility and style.",
        images: [Teleperformance1, Teleperformance2, Teleperformance3],
    },
    {
        id: 18,
        name: "SIEMENS",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Siemens Bangalore is a pivotal hub for Siemens' operations in India, focusing on industry, infrastructure, digital transformation, and healthcare. Known for driving technological innovation and efficiency, Siemens Bangalore supports regional growth through sustainable and advanced solutions. The facility is instrumental in various key projects, contributing significantly to the local economy and technological landscape.`,
        scope: "Our scope in this project includes the supply and installation of acoustic ceiling clouds and baffles, specifically FabriSorb panels with edge painting. These enhancements are designed to improve the acoustic environment within the office spaces.",
        images: [Siemens1, Siemens2, Siemens3],
    },
    {
        id: 17,
        name: "Delhi Public School",
        location: "Bangalore, Karnataka",
        client: "",
        architect: "",
        aboutText: `Delhi Public School Bangalore East is a member of the prestigious DPS family comprising of over a hundred schools located in India as well as overseas.The DPS network in India spans across the geographical extent of our country and beyond. The schools abroad include the ones at Nepal, Indonesia, Singapore, United Arab Emirates, Qatar and Kuwait.`,
        scope: "We were entrusted with the installation of Polysorb Baffles and Polysorb Wall Panels in the swimming pool area. Our design and acoustic treatment successfully minimized reverberation, creating a more pleasant and acoustically efficient environment.",
        images: [DPS1, DPS2, DPS3],
    },
    {
        id: 16,
        name: "Herbalife",
        location: "White field, Bangalore",
        client: "",
        architect: "",
        aboutText: `Herbalife Nutrition Ltd., also known as Herbalife, is an American multinational MLM corporation that develops and sells dietary supplements. The company is incorporated in the Cayman Islands, with its headquarters in California. Its Nutritional Center in JP Nagar, Bangalore features a contact center, creative services, an innovation center, a podcast room, and a shooting studio.`,
        scope: "Our project scope includes the supply and installation of sound insulation and acoustic doors. designed to optimize the acoustic environment and provide a quieter, more productive workspace to meet the high standard of sound quality of their shooting studio.",
        images: [Herbalife1, Herbalife2, Herbalife3],
    },
    {
        id: 15,
        name: "Ford Global Technology and Business Center (GTBC)",
        location: "Sholinganallur, Chennai",
        client: "",
        architect: "",
        aboutText: `Ford's (GTBC), Sholinganallur, Chennai serves as a hub for product development, mobility solutions, and business services. The facility accommodates up to 11,000 employees and features advanced labs, a mobility experience lab, and various amenities, including a food and fitness center, collaborative workspaces, and a daycare facility, fostering a supportive work environment.`,
        scope: "Our scope of the project includes the supply and installation of the Fabriwall system for 18 board rooms. This system enhances acoustic performance and aesthetics, ensuring an optimal environment for meetings and presentations.",
        images: [Ford1, Ford2, Ford3],
    },
    {
        id: 14,
        name: "Bajaj Electronics",
        location: "Mangalore",
        client: "",
        architect: "",
        aboutText: `Founded in 1980 by visionary entrepreneur Mr. Pawan Bajaj, Bajaj Electronics has grown to become a leading electronics retail chain in India. Starting with a small showroom in Lakdikapul, Hyderabad, the company now boasts 151 aesthetically designed stores across Telangana, Mangalore, and Delhi-NCR1. Bajaj Electronics offers a wide range of electronic products from top brands.`,
        scope: "At Bajaj Electronics, we supplied high-quality acoustic plaster and ensured precise application and superior results, enhancing sound absorption. Our expertise created an optimal acoustic environment tailored to the project's requirements.",
        images: [Bajaj1, Bajaj2, Bajaj3],
    },
    {
        id: 13,
        name: "Kodaikanal International School",
        location: "Kodaikanal",
        client: "",
        architect: "",
        aboutText: `Kodaikanal International School (KIS), nestled in the picturesque hills of Kodaikanal, Dindigul, Tamil Nadu, spans 43 acres. This co-educational independent residential school offers grades P-12 offering a robust curriculum and vibrant community. With over a century-long legacy of excellence, Kodaikanal international School's commitment to quality education matches its scenic heights, inspiring students.`,
        scope: "Supply & Installation of acoustical wall panels for the music jamming studio. Acoustically treating the ceiling and walls of the music room to reduce the reverberation time to provide the best performance results when loud music is being played.",
        images: [KIS1, KIS2, KIS3],
    },
    // {
    //     id: 13,
    //     name: "Home Theater, Mangalore",
    //     location: "Mangalore",
    //     client: "",
    //     architect: "",
    //     aboutText: `For any home theater project, ASI aims to create an immersive, high-quality acoustic experience. This state-of-the-art installation will transform the space into a hub of entertainment. Perfect for enjoying movies, music, and more, we deliver superior sound clarity, enhancing the overall experience with top-tier acoustic treatments. Through our expertise, we deliver tailored soulutions.`,
    //     scope: "We are responsible for the complete home theater installation, including the supply and installation of acoustic walls and flooring. Our focus is on enhancing sound quality and aesthetics, ensuring an unparalleled cinematic experience.",
    //     images: [Hometheater1, Hometheater2, Hometheater3],
    // },
    {
        id: 12,
        name: "Hospira Healthcare",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `Hospira Healthcare India PVT. LTD. previously known as Ojas Pharmaceuticals India PVT. LTD., specializes in manufacturing injectable formulations and active pharmaceutical ingredients (APIs). It continues to deliver high-quality pharmaceutical products, contributing significantly to global healthcare. Their expertise in APIs and formulations underscores their pivotal role in the industry.`,
        scope: "At Hospira Healthcare project, our scope included the installation of FabriWALL system which enhanced sound quality and control within their facilities. Our tailored solution ensured an efficient environment to meet their high standards.",
        images: [Hospira1, Hospira2, Hospira3],
    },
    {
        id: 11,
        name: "Christian Medical College",
        location: "Vellore, Tamil Nadu",
        client: "",
        architect: "",
        aboutText: `The Christian Medical College and Hospital (CMC),Vellore, Tamil Nadu, India is a private, educational and research institute that includes a network of primary, secondary and tertiary care hospitals . Founded in 1900 by American missionary Dr. Ida S. Scudder, CMC has brought many significant achievements to India. The institution pioneers medical advancements and exceptional healthcare services.`,
        scope: "We treated the wall partition for 39dB acoustic isolation, added NRC 0.9 absorption wall paneling, and installed suspended ceilings with acoustic isolation. The project covered 2361 sq.ft., including room renovations with the latest technology.",
        images: [CMC1, CMC2, CMC3],
    },
    {
        id: 10,
        name: "Flocert India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Flocert is a leading certifier providing verification services to Fair-trade organizations worldwide. Over the past decade, they have supported the impressive growth of Fair-trade. This movement has positively impacted millions globally. Flocert's dedication ensures the integrity and success of Fair-trade initiatives. They remain at the forefront of promoting equitable trading practices.`,
        scope: "Supply & Installation of acoustical Fabric panels with echo eliminator as backing material for the conference room to reduce the reverberation time to provide better speech intelligibility.",
        images: [Flocert1, Flocert2, Flocert3],
    },
    {
        id: 9,
        name: "Amaryllis Healthcare Pvt Ltd, Bangalore",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Amaryllis Healthcare Private Limited, an ISO Certified Company, has become a vital partner in India's healthcare sector in recent years. Their product range includes disposable surgical drapes, gowns, and a variety of hygiene and protective products. This wide array of offerings ensures comprehensive support for healthcare professionals, enhancing both safety and efficiency across the industry.`,
        scope: "Supply and installation of Noise S.T.O.P Vinyl Barrier and Fabri<span style='color: red;'>wall</span> system with Glass wool to increase sound isolation property of wall and to reduce reverberation time in the conference room which provides better speech intelligibility.",
        images: [Amaryllis1, Amaryllis2, Amaryllis3],
    },
    {
        id: 8,
        name: "General Motors",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `General Motors India Private Limited, headquartered in Bangalore, is a strategic partnership between General Motors and SAIC. The company designs, develops, and manufactures vehicles tailored to the Indian market while contributing to GM's global programs. Despite ceasing car sales in India in 2017, GM India continues to operate its technical center in Bangalore, focusing on engineering and design innovations.`,
        scope: "At General Motors India Private Limited includes the design, supply, and installation of a state-of-the-art anechoic chamber. Our expertise ensured precision in sound testing and noise reduction, supporting GM's innovation and excellence.",
        images: [Generalmotor1, Generalmotor2, Generalmotor3],
    },
    {
        id: 7,
        name: "Naval Base Academy",
        location: "Vishakapatnam",
        client: "",
        architect: "",
        aboutText: `Naval Base Academy in Visakhapatnam is a prestigious institution dedicated to training future officers for the Indian Navy. The academy offers rigorous academic programs alongside intensive physical training, preparing cadets for their roles. With state-of-the-art facilities and a commitment to leadership and discipline, Naval Base Academy shapes the future of India's naval forces.`,
        scope: "Our scope at Naval Base Academy involves the supply and installation of advanced Fabrisorb panels. Our precision and expertise ensure the academy's spaces are equipped for excellence.",
        images: [Navelbase1, Navelbase2, Navelbase3],
    },
    {
        id: 6,
        name: "Twitter India",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Twitter, a vibrant online news and social networking platform, allows users to post and engage with messages known as tweets. Headquartered in San Francisco, California, Twitter, Inc. operates globally with over 25 offices. This dynamic service has revolutionized how information and conversations flow worldwide. It continues to be a driving force in real-time communication and connectivity.`,
        scope: "Now, Twitter’s offices boast enhanced acoustics, creating a more focused and productive atmosphere with our tailored acoustic wall treatments. Our solutions improved sound quality and reduced noise, perfect workspaces.",
        images: [Twitter1, Twitter2, Twitter3],
    },
    {
        id: 5,
        name: "Wipro Technologies",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Wipro Technologies Ltd., a division of Wipro Limited (NYSE:WIT),ranks among the largestglobal IT services, BPO, and Product Engineering companies. Beyond IT, Wipro leads in niche market segments of consumer products and lighting solutions. Their multifaceted expertise drives innovation across industries, solidifying their global presence. This leadership underscores Wipro's commitment to excellence and growth.`,
        scope: "At Wipro, we enhanced sound quality and aesthetics with our acoustic wall treatments. From consultation to installation, we provide toptier sound control solutions,transforming spaces into quieter, more productive environments.",
        images: [Wipro1, Wipro2, Wipro3],
    },
    {
        id: 4,
        name: "Goldman Sachs",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `The Goldman Sachs Group, Inc. is a leading global investment banking, securities and investment management firm that provides a wide range of financial services to a substantial and diversified client base that includes corporations, financial institutions, governments and individuals. Founded in 1869, the firm is headquartered in New York and maintains offices in all major financial centres around the world.`,
        scope: "Our scope includes comprehensive acoustic wall treatments tailored to enhance the acoustic quality and aesthetics of Goldman Sachs' global offices. From consultation to installation, we ensured Topnotch sound control solutions.",
        images: [Goldman1, Goldman2, Goldman3],
    },
    {
        id: 3,
        name: "Sabic Research & Technology Pvt. Ltd",
        location: "Bangalore",
        client: "",
        architect: "",
        aboutText: `Sabic Research and Technology Private Limited, incorporated on 19 February 1998, operates as a Nongovernment company. It is registered with the Registrar of Companies in Bangalore. Sabic focuses on innovative research and technology developments in various sectors, driving forward advancements and solutions. The company's commitment to excellence drives industry transformation`,
        scope: "We expertly supplied and installedacoustical doors and partitions at Sabic, enhancing their spaces with superior soundproofing solutions. Sabic’s commitment to excellence is mirrored in our high- quality acoustical doors.",
        images: [Sabic1, Sabic2, Sabic3],
    },
    {
        id: 2,
        name: "DRC Cinemas",
        location: "Mysore",
        client: "",
        architect: "",
        aboutText: `<strong>Where Dreams Unfold on the Big Screen</strong> : In the heart of Mysore, DRC Cinemas stands tall—a beacon of entertainment and community. As the city’s first multiplex, it brings to life the dreams of countless Mysoreans. Top-of-the-line acoustics and projection, creates a mesmerizing ambiance. The stylish lobby and concession counters invite moviegoers, while diverse language films captivate audiences on the screens.`,
        scope: "In this project, we enhanced the auditory experience by ensuring that every whisper, every explosion, and every note resonates flawlessly within the theatre walls. It’s more than just sound—it’s an immersive journey.",
        images: [DRC1, DRC2, DRC3],
    },
    {
        id: 1,
        name: "Zoho Corporation",
        location: "Chennai",
        client: "",
        architect: "",
        aboutText: `<strong>Empowering Business Solutions</strong> : Zoho Corporation, a global leader in business software, thrives on innovation. Their suite of web-based tools and IT solutions caters to businesses worldwide with commitment of excellence. Zoho bridges efficiency and effectiveness, driving productivity. With a practical focus, they set industry standards. Zoho’s tools empower businesses to achieve their goals with cutting-edge technology.`,
        scope: `<strong>Enhancing Spaces with Acoustic Excellence:</strong> 
        We specialize in supplying and professionally installing two key elements:
        <ol style="margin-left: 20px;">
            <li><strong>Acoustic Panel System</strong></li>
            <li><strong>Acrylic Sheet</strong></li>
        </ol>`,
        images: [Zoho1, Zoho2, Zoho3],
    },
];

const ProjectCard = ({ project }) => {
    return (
        <div className='container mt-5 mb-5'>
            <div className='row'>
                <div className="col-lg-7 mb-5">
                    <div className='top-img'>
                        <img className='top' src={project.images[0]} alt={project.name} />
                    </div>
                    <div className='bottom-img d-flex'>
                        <div>
                            <img className='img-fluid bot-img1' src={project.images[1]} alt={`${project.name}-2`} />
                        </div>
                        <div>
                            <img className='img-fluid bot-img2' src={project.images[2]} alt={`${project.name}-3`} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 project-content">
                    <div className='rno d-flex'><span className='namespan'>{project.id.toString().padStart(2, '0')}. </span><h5>{project.name}</h5></div>
                    <div className='location d-flex'><p>Location :</p><span className='pl-1'>{project.location}</span></div>
                    <p className='client'>Client: {project.client}</p>
                    {project.architect && (
                        <p className='architech'>Architect: {project.architect}</p>
                    )}
                    <p className='abt-project mb-0'>About Project:</p>
                    <p className='para-content' style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: project.aboutText }} />
                    <p className='scope-project mb-0'>Our Scope:</p>
                    <p className='para-content' style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: project.scope }} />
                </div>
            </div>
            <hr className='project-hr' />
        </div>
    );
}

function Project() {
    const projectsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(projectsData.length / projectsPerPage);

    const currentProjects = projectsData.slice((currentPage - 1) * projectsPerPage, currentPage * projectsPerPage);

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <Helmet>
                <title>Turnkey Acoustic Projects - Acoustical Surfaces</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Projects Gallery" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.png" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/projects" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/projects" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/projects" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content projects">
                <div className='container-fluid p-0 pro-banner'>
                    <h3>Recently</h3>
                    <h1>Completed Projects</h1>
                </div>

                {currentProjects.map(project => (
                    <ProjectCard key={project.id} project={project} />
                ))}
                <ScrollToTopButton />

                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end pr-3 pb-3">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} aria-label="Previous">
                                &laquo;
                            </button>
                        </li>
                        {[...Array(totalPages).keys()].map(pageNumber => (
                            <li key={pageNumber + 1} className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)}>
                                    {pageNumber + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">
                                &raquo;
                            </button>
                        </li>
                    </ul>
                </nav>
            </section>
            <Footer />
        </>
    );
}
export default Project;