import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import './search.css';
import ScrollToTopButton from '../ScrollButton';
function search() {
    return (
      
    <>
    <Helmet>
    <meta name="description" content="" />
    <title>Acoustical Surfaces Industries Sitemap</title>
    <meta name="robots" content="follow, index" />
    <link rel="canonical" href="https://www.acousticalsurfaces.in/search" />
    <link rel="alternate" href="https://www.acousticalsurfaces.in/search" hreflang="en-in" />
    <link rel="alternate" href="https://www.acousticalsurfaces.ae/search" hreflang="en-ae" />
    <meta name="geo.placename" content="India" />
    <meta http-equiv="content-language" content="IN" />
    <meta name="geo.region" content="india" />
    </Helmet>
    <Header />
    <section className="content">
        <div className="container">
            <div className="row">
                <div className="col-sm-3 sidemenu">
                    <Sidebar />
                </div>
                <div className="col-sm-9 product-page nopaddding">
                    <div className="container thanks">
                        <div className="megamenu">
                            <Megamenu />
                        </div>
                        <div className="container">
                            <div className="row">
		                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                                    <div className="blog-post">
                                        <div className="single-blog-post">
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Acoustical Surfaces </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/">Home</Link></li>
                                                            <li><Link to="/problem">Identifying noise problems</Link></li>
                                                            <li><Link to="/projects">Projects Gallery</Link></li>
										                    <li><Link to="/international">International Offices and Distributors</Link></li>
										                    <li><Link to="/national">National Offices and Distributors</Link></li>
									                    </ul>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/soundproofingtips">Soundproofing Tips for Soundproofing Problems</Link></li>
										                    <li><Link to="/cad-library-guide">CAD, CSI, Revit Library</Link></li>
										                    <li><Link to="/literature">Resources</Link></li>
										                    <li><Link to="/links">Interesting Web Site Links</Link></li>
										                    <li><Link to="/catalogue">Catalogue</Link></li>
										                    <li><Link to="/contact-us">Contact us</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
						                    <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Key Products</h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/fabrisorb">Fabrisorb</Link></li>
                                                            <li><Link to="/fabriwall">FabriWALL</Link></li>
                                                            <li><Link to="/decopanel">D'ecopanel</Link></li>
                                                            <li><Link to="/woodtec">WoodTec</Link></li>
                                                            <li><Link to="/woodwool">Envirocoustic Wood Wool</Link></li>
                                                            <li><Link to="/foamstop-pu">Sound Absorbing Polyurethane Foams</Link></li>
                                                            <li><Link to="/foamstop-mm">Sound Absorbing Melamine Foams</Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/polymax-polyester">PolyMax</Link></li>
                                                            <li><Link to="/polysorb">Polysorb</Link></li>
                                                            <li><Link to="/acoustical-stretch-fabric">Acousty Fabric</Link></li>
                                                            <li><Link to="/soundproof-interior-doors">Studio 3D Soundproof Interior Doors</Link></li>
                                                            <li><Link to="/ductsound">DUCTsound</Link></li>
                                                            <li><Link to="/acoustimetal">Soundproofing Acoustic Metal</Link></li>
                                                            <li><Link to="/squareline-ceiling-tiles">Acoustical Metal Ceiling Tiles</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Featured Products </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/echo-eliminator">Echo Eliminator</Link></li>
                                                            <li><Link to="/silk-metal-ceiling-tiles">Silk Metal</Link></li>
                                                            <li><Link to="/sound-silencer">Sound Silencer</Link></li>
                                                            <li><Link to="/mass-loaded-vinyl">Mass Loaded Vinyl Sound Barrier</Link></li>
                                                            <li><Link to="/soundbreak-xp">SoundBreak XP Acoustically Enhanced Gypsum Board</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Product Line </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <ul>
                                                            <li><Link to="/absorptive-quilted-curtains">Absorptive Quilted Curtains</Link></li>
                                                            <li><Link to="/acoustic-foam">Acoustic Foam</Link></li>
                                                            <li><Link to="/acoustics-underlayment">Acoustic Underlayment</Link></li>
                                                            <li><Link to="/cinema-port">Acoustic Windows </Link></li>
                                                            <li><Link to="/adjustable-door-seal-kits">Adjustable Jamb Seals</Link></li>
                                                            <li><Link to="/barrier-decoupler">Barrier – Decoupler </Link></li>
                                                            <li><Link to="/barrier-act-ceiling-tiles">Barrier ACT Ceiling Tiles</Link></li>
                                                            <li><Link to="/barrier-act-ceiling-tiles">Barrier-Backed Ceiling Tiles</Link></li>
                                                            <li><Link to="/cellulose-panels">CFAB Cellulose Panels</Link></li>
                                                            <li><Link to="/climate-seal-window-inserts">Climate Seal Window Inserts</Link></li>
                                                            <li><Link to="/cotton-soundwave-eggcrate-panels">Cotton Soundwave Eggcrate Panels</Link></li>
                                                            <li><Link to="/curve-panels">Curve Panels</Link></li>
                                                            <li><Link to="/dba-panels">dBa Ceiling Tiles</Link></li>
                                                            <li><Link to="/duracoustic">Duracoustic</Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <ul>
                                                            <li><Link to="/hanging-acoustic-baffles">Echo Eliminator Baffles</Link></li>
                                                            <li><Link to="/sound-blankets">Exterior Quilted Barrier/Absorbers</Link></li>
                                                            <li><Link to="/glasswoolceiling">Glasswool Ceiling Tiles </Link></li>
                                                            <li><Link to="/green-glue">Green Glue Damping Compound</Link></li>
                                                            <li><Link to="/adjustable-door-seal-kits">Heavy Duty Door Seal Kits</Link></li>
                                                            <li><Link to="/adjustable-door-seal-kits">Magnetic Astragal</Link></li>
                                                            <li><Link to="/melamine-composite-panels">Melamine Composite Panels</Link></li>
                                                            <li><Link to="/painted-nubby-fiberglass-tiles">Painted Nubby Fiberglass Tiles</Link></li>
                                                            <li><Link to="/pvc-sailcloth-baffles">PVC and Sailcloth Baffles</Link></li>
                                                            <li><Link to="/pyramiddiffuser">Pyramid Diffuser </Link></li>
                                                            <li><Link to="/quiet-floor-np">Quiet Floor NP</Link></li>
                                                            <li><Link to="/quiet-floor-walk">Quiet Floor Walk</Link></li>
                                                            <li><Link to="/quilted-exterior-absorbers">Quilted Exterior Absorbers</Link></li>
                                                            <li><Link to="/rsic-1-clips">RSIC-1 Clips</Link></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <ul>
                                                            <li><Link to="/rsic-1ext04">RSIC-1 Ext04 Clips</Link></li>
                                                            <li><Link to="/rubber-isolation-hangers">Rubber Isolation Hangers</Link></li>
                                                            <li><Link to="/sonex-one">Sonex One</Link></li>
                                                            <li><Link to="/sound-silencer-baffles">Sound Silencer Baffles</Link></li>
                                                            <li><Link to="/sound-silencer-ceiling-tiles">Sound Silencer™ Ceiling Tiles</Link></li>
                                                            <li><Link to="/spring-isolation-hangers">Spring Isolation Hangers</Link></li>
                                                            <li><Link to="/spring-isolation-mounts">Spring Isolation Mounts</Link></li>
                                                            <li><Link to="/squareline-ceiling-tiles">Squareline Ceiling Tiles</Link></li>
                                                            <li><Link to="/adjustable-door-seal-kits">Standard Door Seal Kits</Link></li>
                                                            <li><Link to="/stud-isolators">Stud Isolators</Link></li>
                                                            <li><Link to="/soundproof-interior-doors">Studio 3D Doors</Link></li>
                                                            <li><Link to="/studio-windows">Studio Windows</Link></li>
                                                            <li><Link to="/super-w-pads">Super W Pads</Link></li>
                                                            <li><Link to="/ultra-touch-insulation">Ultra Touch Insulation</Link></li>
                                                            <li><Link to="/whisper-wave-clouds">Whisper Wave Clouds</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Catalogue </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="https://www.acousticalsurfaces.in/product-catalogues/fabrisorb/fabrisorb-brochure.pdf" >Product Catalogues</Link></li>
                                                            <li><Link to="https://www.acousticalsurfaces.in/product-datasheet/fabrisorb/fabrisorb-acoustical-clouds.pdf">Product Datasheet/</Link></li>
                                                            <li><Link to="https://www.acousticalsurfaces.in/product-test-reports/fabrisorb/test-report-asi-fabrisorb-20mm-sound-absorption.pdf">Product Test Reports</Link></li>
                                                            <li><Link to="https://www.acousticalsurfaces.in/product-color-chart/decopanel/decopanel-color-chart.pdf">Product Color Chart</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">Privacy Policy </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/terms">Terms of Service</Link></li>
                                                            <li><Link to="/priv-policy">Privacy Policy</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                            <div className="blog-single-bottom-content-box">
                                                <div className="section-title mb-40">
                                                    <h2 className="lhead">GEO REGION </h2>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <ul>
                                                            <li><Link to="/bahrain">Bahrain</Link></li>
                                                            <li><Link to="/india">India</Link></li>
                                                            <li><Link to="/kuwait">Kuwait</Link></li>
                                                            <li><Link to="/oman">Oman</Link></li>
                                                            <li><Link to="/qatar">Qatar</Link></li>
                                                            <li><Link to="/saudi-arabia">Saudi Arabia</Link></li>
                                                            <li><Link to="/dubai">Dubai</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="line-footer"></span>
                                            <div className="portfolio-container2">
                                                <Link to="/" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />
</>
);
}
export default search; 